<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/doctorManage' }">医生信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" label-width="140px" :rules="rules" ref="form">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" style="float: left;" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="tel">
        <el-input v-model="form.tel" style="float: left;" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input v-model="form.pwd" style="float: left;" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card_number">
        <el-input v-model="form.id_card_number" style="float: left;" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="form.sex" placeholder="请选择性别">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input style="float: left;" v-model="form.age" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="挂号费" prop="registration_fee">
        <el-input
          style="float: left;"
          v-model="form.registration_fee"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          style="float: left;"
          type="number"
          v-model="form.sort"
          autocomplete="off"
          placeholder="请输入数字"
        >
        </el-input>
        <span style="float: left;color: #909399;width: 100%;text-align: left;"
          >数字越大排名越靠前</span
        >
      </el-form-item>
      <el-form-item label="平台内科室" prop="dp_id">
        <el-select filterable v-model="form.dp_id" placeholder="请选择平台内科室">
          <el-option v-for="item in keshi" :key="item.value" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在医疗机构" prop="hospital">
        <el-input style="float: left;" v-model="form.hospital" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="医疗机构级别" prop="hospital_rank">
        <el-input
          style="float: left;"
          v-model="form.hospital_rank"
          placeholder="例如三甲医院"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="所在医疗机构科室" prop="depart">
        <el-input style="float: left;" v-model="form.depart" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="职称" prop="rank">
        <el-input style="float: left;" v-model="form.rank" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="擅长治疗" prop="goodat">
        <el-input
          type="textarea"
          rows="6"
          placeholder="请输入擅长治疗"
          v-model="form.goodat"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="从医简介" prop="desc">
        <FullEditor id="doc" rows="10" upload="/"></FullEditor>
      </el-form-item>
      <el-form-item label="医生视频封面图片" style="margin-bottom: 0;">
        <upload :src.sync="form.video_img"></upload>
      </el-form-item>
      <p
        style="margin: 0;text-align: left;padding-left: 140px;font-size: 14px;color: #C0C4CC;margin-bottom: 20px;"
      >
        建议上传尺寸：750*417
      </p>
      <el-form-item label="医生视频地址">
        <el-input style="float: left;" v-model="form.video" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="医生头像" prop="headimg">
        <upload :src.sync="form.headimg"></upload>
      </el-form-item>
      <el-form-item label="签名照片">
        <upload :src.sync="form.sign"></upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import upload from "../components/upload";
import FullEditor from "../components/FullEditor.vue";
// import docsign from "../components/docsign.vue";
export default {
  // props:['type','id'],
  components: {
    upload,
    FullEditor,
    // docsign,
  },
  data() {
    var check = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入手机号"));
      } else if (!this.isCellPhone(value)) {
        //引入methods中封装的检查手机格式的方法
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };
    return {
      desc: "",
      form: {
        name: "",
        tel: "",
        pwd: "",
        sex: "",
        age: "",
        hospital: "",
        hospital_rank: "",
        depart: "",
        rank: "",
        goodat: "",
        img: "",
        desc: "",
        video: "",
        dp_id: "",
        sort: "",
        id_card_number: "",
        registration_fee: "1",
        video_img: "", //医生视频封面图片
        sign:""
      },
      isvideocanplay: false,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [{ required: true, validator: check, trigger: "blur" }],
        // id_card_number: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入初始密码", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        // age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        hospital: [{ required: true, message: "请输入所在医疗机构", trigger: "blur" }],
        // hospital_rank: [{ required: true, message: "请输入医疗机构级别", trigger: "blur" }],
        // depart: [{ required: true, message: "请输入所在医疗机构科室", trigger: "blur" }],
        rank: [{ required: true, message: "请输入职称", trigger: "blur" }],
        goodat: [{ required: true, message: "请输入擅长治疗", trigger: "blur" }],
        desc: [{ required: true, message: "请输入从医简介", trigger: "blur" }],
        dp_id: [{ required: true, message: "请选择平台内科室", trigger: "blur" }],
        headimg: [{ required: true, message: "请上传医生头像", trigger: "blur" }],
      },
      options: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          lebel: "女",
        },
      ],
      keshi: [],
      title: "添加医生",
      type: "",
      id: "",
    };
  },
  watch: {
    "form.video": {
      deep: true,
      handler(value, oldValue) {
        if (value != "") {
          this.check(value);
        }
      },
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.getkeshilist();
    if (this.type != "new") {
      this.title = "编辑医生";
      this.axios
        .get("/gu/get_gu_doc_by_id?id=" + this.id)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res)
            this.form = res.data.result;
            if (res.data.result.desc != "") {
              let txt = this.htmlDecode(res.data.result.desc);
              this.$nextTick(() =>
                setTimeout(() => {
                  tinymce.activeEditor.setContent(txt);
                }, 300)
              );
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    getkeshilist() {
      this.axios
        .get("/gu/get_gu_department")
        .then((res) => {
          if (res.data.code == 0) {
            this.keshi = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //检查手机号
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    check(value) {
      var video = document.createElement("video");
      video.src = value;
      var _this = this;
      video.onerror = function() {
        // alert("Can not play");
        _this.isvideocanplay = false;
        _this.$message({
          message: "请填写正确的视频地址",
          type: "error",
        });
      };
      video.oncanplay = function() {
        // alert("Can start playing video");
        _this.isvideocanplay = true;
      };
    },
    onSubmit() {
      // if (this.form.tel == "") {
      //   this.$message({
      //     message: "请填写手机号",
      //     type: "error",
      //   });
      //   return;
      // }
      let url = "";
      let str = "";
      if (this.type == "new") {
        url = "/gu/insert_gu_doc";
        str = "添加成功";
      } else {
        url = "/gu/update_gu_doc";
        str = "修改成功";
        this.form.id = this.id;
      }

      let data = tinyMCE.activeEditor.getContent();
      this.form.desc = this.htmlEncode(data);

      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.video == "") {
          } else {
            if (this.isvideocanplay) {
              if (this.form.video_img == "") {
                this.$message({
                  message: "请上传视频封面",
                  type: "error",
                });
                return;
              }
            } else {
              this.$message({
                message: "请上传正确的视频地址",
                type: "error",
              });
              return;
            }
          }
          console.log(this.form);
          this.axios
            .post(url, this.qs.stringify(this.form))
            .then((res) => {
              if (res.data.code == 0) {
                this.dialogFormVisible = false;
                this.form = {};
                this.$message({
                  message: str,
                  type: "success",
                });
                this.$router.push("/doctorManage");
              } else {
                this.$message(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          this.$message({
            message: "请补全信息",
            type: "error",
          });
          return false;
        }
      });
    },
    cancel() {
      this.$router.push("/doctorManage");
    },
    htmlEncode: function(html) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      temp.textContent != undefined ? (temp.textContent = html) : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    /*2.用浏览器内部转换器实现html解码（反转义）*/
    htmlDecode: function(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.el-form {
  width: 100%;
  margin-top: 20px;
}

.el-select,
.el-input,
.el-textarea {
  width: 50%;
  float: left;
}
</style>
