<style>
.avatar-uploader {
  float: left;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<template>
  <div>
    <el-upload
      class="avatar-uploader"
      action="    "
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :http-request="upload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  name: "upload",
  props: ["src"],
  mounted() {
    this.imageUrl = this.src;
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  watch: {
    src(old, nw) {
      this.imageUrl = old;
    },
  },
  methods: {
    upload(content) {
      console.log(content);
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      console.log(formData);
      this.axios
        .post("http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php", formData)
        // .post("http://112.250.105.14:10087/qiniu/examples/upload_simple_file.php", formData)
        .then((rs) => {
          if (rs.data.code == 0) {
            console.log(rs);
            this.imageUrl = rs.data.file;
            this.$emit("update:src", this.imageUrl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isJPG = types.includes(file.type);
      //const isJPG = file.type === "image/jpeg/png/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M && isJPG;
    },
  },
};
</script>
